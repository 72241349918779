$(function () {
  var menugral = $('#menu'),
      menuresp = $('#menu-resp'),
      network = $('#network');
  menuresp.on('click', function () {
    if($(window).width() < 1024){
      menugral.slideToggle();
      network.slideToggle();
    }
  });
  // var submenu = $('.sub-menu'),
  //     sub = $(.sub);
  $('.sub-menu').on('mouseenter', function () {
    if($(window).width() > 1024){
      $('.sub-menu > ul').slideToggle();
    }
  });
  $('.sub-menu').on('mouseleave', function () {
    if($(window).width() > 1024){
      $('.sub-menu > ul').slideToggle();
    }
  });
  var year = new Date().getFullYear();
  var copy = document.getElementById("copy");
  copy.innerHTML = " " + year + " ";
});